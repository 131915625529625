import { ReactElement } from 'react'
import { RouteComponentProps } from 'react-router'
import { IActionCreatorAsync } from 'core/actions/Actions.interfaces'
import { IPermissions } from 'core/selectors/Profile.selectors'
import { IMatch, IMatchParams } from 'shared/interfaces'
import {
  IAccessScope,
  ILicense,
  IOauthClient,
  IOrganization,
  ITenant,
  IUser,
  IUserProfile
} from 'reducers/Reducers.interfaces'
import { ISigningProvider } from '../create-account-signing-client/AccountSigningClient.types'

export enum MainView {
  organizations = '1',
  accountSignIn = '2'
}

export enum Sections {
  Organizations = 'organizations',
  License = 'license',
  AccountSignIn = 'account_sign_in',
  DocumentSigningClients = 'document_signing_clients',
  Features = "features",
  Integrations = "oauthIntegrations"
}

export enum Applications {
  CAManager = 'ca_manager',
  MPKI = 'enterprise_manager',
  IOT = 'device_manager',
  SSM = 'secure_software_manager',
  DSM = 'document_signing_manager',
  AM = 'account_manager',
  DAM = 'discovery_automation_manager',
  DEVCTM = 'device_trust_manager',

}

export const defaultSections = [
  Sections.Organizations,
  Sections.AccountSignIn,
  Sections.DocumentSigningClients,
  Sections.License
]

export interface IAccordionItem {
  headline: string
  key: string
  view: ReactElement
  link: string
  anchorId: string
  disablePanel?: boolean
  notCollapsible?: boolean
}

export interface IlicenseCard {
  licenseType: string
  remaining: number
  totalAllocated: number
  id: number
  featureId: string
  licenseAllocation: string
  used: string
}

export interface ILicenseInfo {
  id: string,
  title: string,
  data: [IlicenseCard],
  visible: boolean,
  canEdit: boolean
}

export interface IFeatureInfo {
  header: string,
  context: string,
  appId: string,
  appCode: string,
  features: IFeatures[],
  defaultFeatures: IDefaultFeatures[]
}

export interface IDefaultFeatures {
  featureId: string,
  featureName: string,
  featureDetails: string,
  featureDisplayOrder: number
}

export interface IFeatures {
  featureId: string,
  featureName: string,
  featureDetails: string,
  featureEnabled: boolean,
  featureAdditionalInformation: string[],
  errorMessage: string,
  date?: any
}

export interface IDetailProps {
  content: [
    {
      name: string,
      value: any
    }]
  }   
export interface ITenantState {
  tenant?: ITenant
  openedSections: Array<string>
  oauthClients?: IOauthClient[]
  isDeleteSigningClientModalVisible: boolean
  signingClientID: string
  editMode: Applications | null
  licenses: any
  license?: ILicense
  licenseLoad: boolean
  showSideRail?: boolean
  licenseHistoryDetailsResponse: Map<string, Array<LicenseHistoryResponse>>
  tooltipVisible: boolean
}

export interface ITenantOwnProps {
  permissions: IPermissions
  match: IMatch<IMatchParams>
}

interface ITenantDispatchProps {
  fetchTenantById: IActionCreatorAsync
  fetchUsersList: IActionCreatorAsync
  fetchOrganizations: IActionCreatorAsync
  fetchAssignedTemplatesToTenant: IActionCreatorAsync
  updateAccountOauthConfig: IActionCreatorAsync
  deleteAccountOauthClientsById: IActionCreatorAsync
  fetchAllOauthClients: IActionCreatorAsync
  updateLicenseByApp: IActionCreatorAsync
  getLicense: IActionCreatorAsync
  fetchUserProfile: IActionCreatorAsync
  createLicenseAllocationUpdateEvent: IActionCreatorAsync
  getLicenseLimitEvents: IActionCreatorAsync
  getLicenseLimitDetails: IActionCreatorAsync
  getFeatureByAccount: IActionCreatorAsync
  fetchProfile: IActionCreatorAsync
}

export interface ITenantStateProps {
  tenant?: ITenant
  accessScope?: IAccessScope
  providers: ISigningProvider[]
  userProfile?: IUserProfile
  license?: ILicense
  profile?: IUser
  organizations?: IOrganization[]
}

export type ITenantProps = ITenantStateProps &
  ITenantOwnProps &
  ITenantDispatchProps &
  RouteComponentProps

export interface IAsideComponentOwnProps {
  tenantName: string
  tenantId: string
  active: boolean
  accessScope?: IAccessScope
}

export interface LicenseHistoryResponse {
  feature: string
  quantity: number
  limit: number
  actor: string
  timestamp: string
  app_code: string
}

export interface AccordianSection {
  headline: string
  key: string
  view?: string
  link: string
  anchorId: string
}