import React, { lazy, ReactElement } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import PublicEnrollment from 'public-pages/enrollment/public-enrollment/PublicEnrollment'
import Login from './public-pages/login/Login'
import SecureLayout from 'secure-layout'
import Access from 'secure-pages/access'
import { RouterConfig } from 'core/utils/constants'
import { BasePathConst, accountUrl } from 'configs/Path.configs'
import History from 'core/history/history'
import Redirecting from 'secure-pages/redirecting'
import SetupLicense from 'public-pages/license/setup-license'
import SetupUser from 'public-pages/setup-user'
const EditRoles = lazy(() => import('secure-pages/users/edit-roles/EditRoles'))
const Agreement = lazy(() => import('./secure-pages/agreement'));
const SelfServiceUser = lazy(() => import('./public-pages/self-service-user/SelfServiceUser'));

const AppRoutes = (): ReactElement => {
  const GenerateClientAuth = lazy(() => import('./secure-pages/client-auth'));
  const Verification = lazy(() => import('./public-pages/verification/Verification'));
  const AdminProfile = lazy(() => import('./secure-pages/profile'));
  const EditProfile = lazy(() => import('./secure-pages/profile/edit-profile/EditProfile'));
  const Users = lazy(() => import('./secure-pages/users'));
  const CreateUser = lazy(() => import('./secure-pages/users/create-user/CreateUser'));
  const UserDetails = lazy(() => import('./secure-pages/users/user-details/UserDetails'));
  const EditUser = lazy(() => import('./secure-pages/users/edit-user/EditUser'));
  const ServiceUserList = lazy(() => import('./secure-pages/service-user/ServiceUserList'));
  const CreateServiceUser = lazy(() => import('./secure-pages/service-user/create/CreateServiceUser'));
  const UpdateServiceUser = lazy(() => import('./secure-pages/service-user/update/UpdateServiceUser'));
  const ServiceUser = lazy(() => import('./secure-pages/service-user/ServiceUserDetailPage'));
  const ClientAuthLoginVerificationError = lazy(() => import('./public-pages/errors/ClientAuthLoginVerificationError'));
  const SamlVerificationError = lazy(() => import('./public-pages/errors/SamlVerificationError'));
  const UpdateLicenseError = lazy(() => import('./public-pages/errors/UpdateLicenseError'));
  const AccountExpiredError = lazy(() => import('./public-pages/errors/AccountExpiredError'));
  const CertificateObtaining = lazy(() => import('./public-pages/certificate/CertificateObtaining'));
  const UploadCert = lazy(() => import('./secure-pages/profile/upload-cert/UploadCert'));
  const EditUserWithRole = lazy(() => import('./secure-pages/users/edit-user-with-role/EditUserWithRole'));
  const CreateUserWithRole = lazy(() => import('./secure-pages/users/create-user-with-role/CreateUserWithRole'));
  const CreateServiceUserWithRole = lazy(() => import('./secure-pages/users/create-service-user-with-role/CreateServiceUserWithRole'));
  const EditServiceUserWithRole = lazy(() => import('./secure-pages/users/edit-service-user-with-role/EditServiceUserWithRole'));
  const PageNotFound = lazy(() => import('./secure-pages/page-not-found/PageNotFound'));
  const Organizations = lazy(() => import('./secure-pages/organizations/Organizations'));
  const OrganizationsCreate = lazy(() => import('./secure-pages/organizations/organizations-create/OrganizationsCreate'));
  const Organization = lazy(() => import('./secure-pages/organizations/organization/Organization'));
  const OrganizationsEdit = lazy(() => import('./secure-pages/organizations/organizations-edit/OrganizationsEdit'));
  const OrganizationsCreateContact = lazy(() => import('./secure-pages/organizations/contacts/create/Create'));
  const OrganizationEditContact = lazy(() => import('./secure-pages/organizations/contacts/edit/Edit'));
  const BulkUserUpload = lazy(() => import('./secure-pages/users/bulk-user-upload/BulkUserUpload'));
  const BulkUserUploadWithRole = lazy(() => import('./secure-pages/users/bulk-user-upload-with-role/BulkUserUploadWithRole'));
  const CreateApiToken = lazy(() => import('./secure-pages/api-tokens'));
  const EditApiToken = lazy(() => import('./secure-pages/api-tokens/edit-api-token'));
  const UpdateLicense = lazy(() => import('./public-pages/license/update-license'));
  const CreateAccount = lazy(() => import('./secure-pages/create-account/CreateAccount'));
  const Tenants = lazy(() => import('./secure-pages/tenants/Tenants.page'));
  const Tenant = lazy(() => import('./secure-pages/tenants/tenant/Tenant'));
  const EditTenant = lazy(() => import('./secure-pages/tenants/edit/EditTenant'));
  const AccountSigningClientCreate = lazy(() => import('./secure-pages/tenants/create-account-signing-client/AccountSigningClient'));
  const AccountSigningClientEdit = lazy(() => import('./secure-pages/tenants/create-account-signing-client/edit/AccountSigningClientEdit'));
  const OpenIdConnectIntegration = lazy(() => import('./secure-pages/tenants/tenant/open-id-connect-integration/OpenIdConnectIntegration'));
  const CreateAccountSignIn = lazy(() => import('./secure-pages/tenants/create-account-sign-in'));
  const EditStandardSignIn = lazy(() => import('./secure-pages/tenants/create-account-sign-in/edit/EditStandardSignIn'));
  const EditMfa = lazy(() => import('./secure-pages/tenants/create-account-sign-in/edit/EditMfa'));
  const AccountOauthAdd = lazy(() => import('./secure-pages/settings/oauth-add/OauthAdd'));
  const AccountOauthEdit =  lazy(() => import('./secure-pages/settings/oauth-edit/OauthEdit'));
  const Settings = lazy(() => import('./secure-pages/settings/Settings'));
  const OauthAdd = lazy(() => import('./secure-pages/settings/oauth-add/OauthAdd'));
  const OauthEdit = lazy(() => import('./secure-pages/settings/oauth-edit/OauthEdit'));
  const Reporting = lazy(() => import('./secure-pages/reporting/Reporting'));
  const AuditLogs = lazy(() => import('./secure-pages/reporting/audit-logs/AuditLogs'));
  const AuditEventsDetail = lazy(() => import('./secure-pages/reporting/audit-logs-detail/AuditEventsDetail'));
  const ClientAuthCertLoginUpdate = lazy(() => import('./secure-pages/client-auth-cert-authentication/ClientAuthCertLoginUpdate'));
  const RoleDetail = lazy(() => import('./secure-pages/users/role-detail/RoleDetail'));
  const CloneRole = lazy(() => import('./secure-pages/users/clone-role/CloneRole'));
  const EditRole = lazy(() => import('./secure-pages/users/edit-role/EditRole'));
  const CreateRole = lazy(() => import('./secure-pages/users/create-role/CreateRole'));
  const ManageRoles = lazy(() => import('./secure-pages/users/manage-roles/ManageRoles'));

  return (
    <Router history={History}>
      <Switch>
        {/* Public routes */}
        <Route exact path={RouterConfig.urlMap.setupLicense} component={SetupLicense} />
        <Route exact path={`${BasePathConst}/enrollment/:enrollmentId/:enrollmentCode`} component={PublicEnrollment} />
        <Route exact path={`${BasePathConst}/create-user-account`} component={SelfServiceUser} />
        <Route exact path={RouterConfig.urlMap.setupUser} component={SetupUser} />
        <Route exact path={RouterConfig.urlMap.login} component={Redirecting}/>
        <Route exact path={`${BasePathConst}/verify/:userId/:verificationCode`} component={Verification}/>
        <Route exact path={`${BasePathConst}/login`} component={Login} />
        <Route exact path={`${BasePathConst}`} component={() => {
          window.location.href = `${accountUrl}`
          return null
        }}/>

        <Route exact path={`/`} component={() => {
          window.location.href = `${accountUrl}`
          return null
        }}/>

        <Route
          exact
          path={`${BasePathConst}/certificate-pickup`}
          component={CertificateObtaining}
        />

        <Route path={RouterConfig.urlMap.samlVerificationError} component={SamlVerificationError} />
        <Route path={RouterConfig.urlMap.clientAuthLoginVerificationError} component={ClientAuthLoginVerificationError} />
        <SecureLayout exact path={RouterConfig.urlMap.updateLicenseError} component={UpdateLicenseError} />
        <Route exact path={RouterConfig.urlMap.accountExpiredError} component={AccountExpiredError} />

        <Route path={`/imauth/api/v1/logout`} component={Redirecting}/>
        <Route path={`/imauth/api/v1/login`} component={Redirecting}/>
        <Route path={`/certificate-authority`} component={Redirecting}/>
        <Route path={`/mpki/dashboard`} component={Redirecting} />
        <Route path={`/iot/dashboard`} component={Redirecting} />
        <Route path={`/signingmanager`} component={Redirecting} />

        {/* Agreement */}
        <Route exact path={`${BasePathConst}/agreement/:userId`} component={Agreement} />

        {/* Admin profile */}
        <SecureLayout exact path={RouterConfig.urlMap.adminProfile} component={AdminProfile} />
        <SecureLayout exact path={RouterConfig.urlMap.editAdminProfile} component={EditProfile} />
        <SecureLayout exact path={RouterConfig.administrators.uploadCert()} component={UploadCert} />

        {/* Access */}
        <SecureLayout exact path={RouterConfig.urlMap.access} component={Access} />

        {/* Administrators */}
        <SecureLayout exact path={RouterConfig.administrators.table} component={Users} />
        <SecureLayout exact path={RouterConfig.administrators.create} component={CreateUser} />
        <SecureLayout exact path={RouterConfig.administrators.rolesEdit()} component={EditUserWithRole}/>
        <SecureLayout exact path={RouterConfig.administrators.editRoles()} component={EditRoles}/>
        <SecureLayout exact path={RouterConfig.administrators.createUserWithRole} component={CreateUserWithRole} />
        <SecureLayout exact path={RouterConfig.administrators.createServiceUserWithRole} component={CreateServiceUserWithRole} />
        <SecureLayout exact path={RouterConfig.administrators.editServiceUserWithRole()} component={EditServiceUserWithRole} />
        <SecureLayout exact path={RouterConfig.administrators.bulkCreate} component={BulkUserUpload} />
        <SecureLayout exact path={RouterConfig.administrators.bulkCreateWithRole} component={BulkUserUploadWithRole} />
        <SecureLayout exact path={RouterConfig.administrators.details()} component={UserDetails} />
        <SecureLayout exact path={RouterConfig.administrators.edit()} component={EditUser} />

        {/* Service user */}
        <SecureLayout exact path={RouterConfig.urlMap.serviceUser} component={ServiceUserList} />
        <SecureLayout exact path={RouterConfig.serviceUser.create} component={CreateServiceUser} />
        <SecureLayout exact path={RouterConfig.serviceUser.details()} component={ServiceUser} />
        <SecureLayout exact path={RouterConfig.serviceUser.edit()} component={UpdateServiceUser} />

        {/* API tokens */}
        <SecureLayout exact path={RouterConfig.administrators.apiToken()} component={CreateApiToken} />
        <SecureLayout exact path={RouterConfig.administrators.apiTokenEdit()} component={EditApiToken} />

        {/* Client Auth */}
        <SecureLayout exact path={RouterConfig.administrators.clientAuth()} component={GenerateClientAuth} />
        <SecureLayout exact path={RouterConfig.serviceUser.clientAuth()} component={GenerateClientAuth} />

        {/* Accounts */}
        <SecureLayout exact path={RouterConfig.accounts.table} component={Tenants} />
        <SecureLayout exact path={RouterConfig.accounts.createAccount} component={CreateAccount} />
        <SecureLayout exact path={RouterConfig.accounts.details()} component={Tenant} />
        <SecureLayout exact path={RouterConfig.accounts.edit()} component={EditTenant} />
        <SecureLayout exact path={RouterConfig.accounts.createAccountSignIn()} component={CreateAccountSignIn} />
        <SecureLayout exact path={RouterConfig.accounts.editStandardSignIn()} component={EditStandardSignIn} />
        <SecureLayout exact path={RouterConfig.accounts.editMfa()} component={EditMfa} />
        <SecureLayout exact path={RouterConfig.accounts.createAccountSigningClient()} component={AccountSigningClientCreate} />
        <SecureLayout exact path={RouterConfig.accounts.editAccountSigningClient()} component={AccountSigningClientEdit} />
        <SecureLayout exact path={RouterConfig.accounts.openIdIntegration()} component={OpenIdConnectIntegration} />
        <SecureLayout exact path={RouterConfig.accounts.addOauth()} component={AccountOauthAdd} />
        <SecureLayout exact path={RouterConfig.accounts.editOauth()} component={AccountOauthEdit}/>


        {/* Organizations */}
        <SecureLayout exact path={RouterConfig.organizations.table} component={Organizations} />
        <SecureLayout exact path={RouterConfig.organizations.create} component={OrganizationsCreate} />
        <SecureLayout exact path={RouterConfig.organizations.details()} component={Organization} />
        <SecureLayout exact path={RouterConfig.organizations.edit()} component={OrganizationsEdit} />
        <SecureLayout exact path={RouterConfig.organizations.createContact()} component={OrganizationsCreateContact} />
        <SecureLayout exact path={RouterConfig.organizations.editContact()} component={OrganizationEditContact} />

        {/* Settings */}
        <SecureLayout exact path={RouterConfig.platformSettings.settings} component={Settings} />
        <SecureLayout exact path={RouterConfig.platformSettings.addOauth()} component={OauthAdd} />
        <SecureLayout exact path={RouterConfig.platformSettings.editOauth()} component={OauthEdit} />
        <SecureLayout exact path={RouterConfig.platformSettings.updateLicense} component={UpdateLicense} />
        <SecureLayout exact path={RouterConfig.platformSettings.updateStandardSignIn()} component={EditStandardSignIn} />
        <SecureLayout exact path={RouterConfig.platformSettings.addAccountSignIn()} component={CreateAccountSignIn} />
        <SecureLayout exact path={RouterConfig.platformSettings.updateMfa()} component={EditMfa} />
        <SecureLayout exact path={RouterConfig.platformSettings.settingsOpenIdIntegration()} component={OpenIdConnectIntegration} />

        {/* Reporting */}
        <SecureLayout exact path={RouterConfig.urlMap.reporting} component={Reporting} />
        <SecureLayout exact path={RouterConfig.logging.table} component={AuditLogs} />
        <SecureLayout exact path={RouterConfig.logging.details()} component={AuditEventsDetail} />

        {/* Client auth certification authentication */}
        <SecureLayout exact path={RouterConfig.accounts.updateClientAuthCertLogin()} component={ClientAuthCertLoginUpdate} />

        {/* Manage user roles */}
        <SecureLayout exact path={RouterConfig.manageUserRoles.roles} component={ManageRoles}/>
        <SecureLayout exact path={RouterConfig.manageUserRoles.create()} component={CreateRole}/>
        <SecureLayout exact path={RouterConfig.manageUserRoles.edit()} component={EditRole}/>
        <SecureLayout exact path={RouterConfig.manageUserRoles.clone()} component={CloneRole}/>
        <SecureLayout exact path={RouterConfig.manageUserRoles.detail()} component={RoleDetail}/>

        {/* Page not found all other routes should be placed above*/}
        <SecureLayout path='' component={PageNotFound} />
      </Switch>
    </Router>
  )
}

export default AppRoutes