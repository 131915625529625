import React, { Component, ReactNode } from 'react'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import KeyboardEvent from 'jsdom'
import { fetchProfile } from '../core/actions/Profile.actions'
import {
  Header,
  IToastNotification,
  Navigation,
  Spinner,
  ToasterContainer,
  ToasterMessageType
} from '@digicert/dcone-common-ui'
import Breadcrumbs from './breadcrumbs'
import { imauthLoginUrl } from 'configs/Path.configs'
import {
  ACCOUNT_EXPIRY_NOTIFICATION,
  DCONE_REFRESH_TOKEN_COOKIE_NAME,
  ID_SECURE_LAYOUT,
  RouterConfig
} from 'core/utils/constants'
import { fetchFeatures, fetchLocales } from 'core/actions/Settings.actions'
import { getPermissions } from 'core/selectors/Profile.selectors'
import { IAppState } from 'core/store/store'
import history from 'core/history/history'

import { ISecureLayoutProps, ISecureLayoutState } from './SecureLayout.interfaces'
import { KEY_BACKSPACE } from './SecureLayout.constants'
import { loginSuccess, logout } from 'core/actions/Auth.actions'
import Cookies from 'js-cookie'
import { IAccessScope } from 'reducers/Reducers.interfaces'
import { INavigationItem } from 'configs/Navigation.config'
import { faCog, faFileChartLine, faFolderTree, faGlobe, faUnlockAlt } from '@fortawesome/pro-light-svg-icons'
import { translate } from 'shared/helpers/utils'
import { initLocalization } from 'core/actions/Localization.actions'
import styles from './SecureLayout.module.scss'
import moment from 'moment'
import { getItemFromLS, setItemToLS } from 'shared/helpers/LocalStorage'

class SecureLayout extends Component<ISecureLayoutProps, ISecureLayoutState> {
  constructor(props: ISecureLayoutProps) {
    super(props)

    this.state = {
      isRenderEnabled: false,
      selectedLang: 'en_US',
      isPendoInitialized: false,
      isLoading: true
    }
  }

  private skipBackSpaceKey = (e: KeyboardEvent) => {
    if (e.keyCode === KEY_BACKSPACE && e.target.tagName !== 'INPUT') {
      e.preventDefault()
    }
  }

  public componentDidMount(): void {
    this.setState({
      pathName: window.location.pathname
    })

    if (!Cookies.get(DCONE_REFRESH_TOKEN_COOKIE_NAME)) {
      history.push(`${imauthLoginUrl}?returnPath=` + window.location.pathname)
    }

    if (this.props.isAuth) {
      this.props.fetchLocales().then(() => {
        this.setState({isLoading: false})
      })
    }

    const { userAgent } = window.navigator
    if (userAgent.includes('Firefox') || userAgent.includes('Windows')) {
      document.addEventListener('keydown', this.skipBackSpaceKey)
    }
  }

  public setPendoData(userProfileData): void {
    window.pendo.initialize({
      visitor: {
        id: userProfileData.id,
        email: userProfileData.email,
        full_name: userProfileData.first_name + ' ' + userProfileData.last_name,
        user_id: userProfileData.primary_account_id,
        first_name: userProfileData.first_name,
        last_name: userProfileData.last_name,
        access_to_am: userProfileData.applications.some((application) => application.app_code === 'account_manager'),
        access_to_ca: userProfileData.applications.some((application) => application.app_code === 'ca_manager'),
        access_to_ssm: userProfileData.applications.some((application) => application.app_code === 'secure_software_manager'),
        access_to_iot: userProfileData.applications.some((application) => application.app_code === 'device_manager'),
        access_to_epki: userProfileData.applications.some((application) => application.app_code === 'enterprise_manager'),
        access_to_dsm: userProfileData.applications.some((application) => application.app_code === 'document_signing_manager'),
        access_to_dam: userProfileData.applications.some((application) => application.app_code === 'discovery_automation_manager'),
        account_type: userProfileData.access_scope,
        application_language_setting: userProfileData.locale
      },
      account: {
        id: userProfileData.primary_account_id,
        name: userProfileData.access_scope === 'system'
          ? translate('accounts.systemAccount') as string
          : userProfileData.accounts.filter(item => item.id === userProfileData.primary_account_id)[0].name
      }
    });
    this.setState({
      isPendoInitialized: true
    })
  }

  public componentDidUpdate(prevProps: Readonly<ISecureLayoutProps>): void {
    document.removeEventListener('keydown', this.skipBackSpaceKey)

    const { activeLanguage } = this.props

    if(window.pendo && this.props.userProfile && !this.state.isPendoInitialized){
      this.setPendoData(this.props.userProfile)
    }

    if (!prevProps.isAuth && this.props.isAuth) {
      this.props.fetchLocales().then(() => {
        this.props.fetchProfile().then((response: any) => {
          this.props.setActiveLanguage(response.locale.split('_')[0])
          this.setState({isRenderEnabled: true})
          this.props.fetchFeatures().then()
          if (activeLanguage && !this.state.isLoading) {
            this.setState({
              isRenderEnabled: true
            })
          }
        })
      })
    }

    // Resolves ticket: https://digicertinc.atlassian.net/browse/DOCORE-2527
    // content-wrap scroll position in some cases starts in previous pages vertical scroll position
    const contentWrap = document.getElementById('content_wrap')

    // to remedy changes where is loading state only gets set if this.props.isAuth and after fetching locales
    if(contentWrap && this.state.isLoading){
      this.setState({isLoading: false})
    }

    if(this.state.pathName !== window.location.pathname && !this.state.isLoading) {
      this.setState({
        pathName: window.location.pathname
      })

      // sets immediately
      if(contentWrap) {
        contentWrap.scrollTop = 0
      }

      // for uses cases when scroll position is retained from previous route
      setTimeout(() => {
        if(contentWrap && contentWrap.scrollTop !== 0) {
          contentWrap.scrollTop = 0
        }
      }, 100)
    }
  }

  public checkPermission(navigation : INavigationItem[] , permissions: Array<boolean>, navId: string) : INavigationItem[] {
    const navigationFilteredWithPermission = navigation
    if (!permissions.includes(true)) {
      navigation = navigationFilteredWithPermission.filter((item) => {
        if (item.id !== navId) {
          return item
        }
      })
    }
    return navigation
  }

  public render(): ReactNode {
    const {
      permissions,
      title,
      licenseStatus,
      component: Component,
      withoutBreadcrumbs,
      headerPermissions,
      logout,
      accounts,
      accessScope,
      primaryAccount,
      userId,
      ...rest
    } = this.props

    const headerAccounts = accounts.map((account) => {
      return {
        id: account.id,
        name: account.name
      }
    })

    const headerProps = {
      permissions: headerPermissions,
      logout,
      accounts: headerAccounts
    }

    const accountManagerNavigation: any = [
      {
        name: translate('navigation.accounts') as string,
        path: RouterConfig.urlMap.accounts,
        icon: faFolderTree,
        id: 'navItemAccounts'
      },
      {
        name: translate('navigation.organizations')  as string,
        path: RouterConfig.urlMap.organizations,
        icon: faGlobe,
        id: 'navItemOrganizations'
      },
      {
        name: translate('navigation.access') as string,
        path: RouterConfig.urlMap.access,
        icon: faUnlockAlt,
        id: 'navItemAccess',
        subItems: [
          {
            name: translate('navigation.administrators')  as string,
            path: RouterConfig.urlMap.administrators,
            parent: 'navItemAccess',
            id: 'navItemAdministrators'
          },
          {
            name: translate('navigation.serviceUser') as string,
            path: RouterConfig.urlMap.serviceUser,
            parent: 'navItemAccess',
            id: 'navItemServiceUser'
          },
          {
            name: translate('navigation.userRoles') as string,
            path: RouterConfig.manageUserRoles.roles,
            parent: 'navItemAccess',
            id: 'navUserRoles'
          }
        ]
      },
      {
        name: translate('navigation.reporting') as string,
        path: RouterConfig.urlMap.reporting,
        icon: faFileChartLine,
        id: 'navItemReporting',
        subItems: [
          {
            name: translate('navigation.logging') as string,
            path: RouterConfig.urlMap.logging,
            parent: 'navItemReporting',
            id: 'navItemLogging'
          }
        ]
      },
      {
        name: translate('navigation.platformSettings') as string,
        path: RouterConfig.platformSettings.settings,
        icon: faCog,
        id: 'navItemSettings'
      }
    ]

    let notification: IToastNotification[] = []

    if (getItemFromLS(ACCOUNT_EXPIRY_NOTIFICATION) == null) {
      if ( accessScope && accounts.length > 0 && primaryAccount) {
        const primaryAccountDetails = accounts.find(t => t.id == primaryAccount)
        if (primaryAccountDetails != undefined && accessScope == IAccessScope.account) {
          const diff = -moment().diff(primaryAccountDetails.service_period.to, 'days')
          if (!primaryAccountDetails.active) {
            notification = [
              { type: ToasterMessageType.ERROR,
                description: <Translate id={'common.form.accountExpiryNotification.expired'}/>,
                id: 'account-expired-notification'
              }
            ]
          } else if (diff <= 30) {
            notification = [//
              { type: ToasterMessageType.ERROR,
                description: <Translate id={'common.form.accountExpiryNotification.expiring'} data={{ diff: diff }}/>,
                id: 'account-expiry-notification'
              }
            ]
          }
          setTimeout(() => {
            setItemToLS(ACCOUNT_EXPIRY_NOTIFICATION, 'true')
          }, 1000)
        }
      }
    }

    if (!permissions) {
      return (
        <div className='initial-spin-wrap'>
          <Spinner/>
        </div>
      )
    }

    return (
      <Route
        {...rest}
        render={(props) => {
          if (!this.state.isRenderEnabled) return null

          let navigation = accountManagerNavigation
          if (permissions) {
            if (
              (!permissions.canManageSystemSettings && !permissions.canViewLicense && !permissions.canManageLicense) ||
              this.props.accessScope === IAccessScope.account
            ) {
              navigation = navigation.filter((item) => {
                if (item.id !== 'navItemSettings') {
                  return item
                }
              })
            }
            navigation = navigation.filter((item) => {
              if (item.id === 'navItemAccess') {
                const permissionArray = [permissions.canViewUser, permissions.canManageAccountUser, permissions.canManageSystemUser]
                if (!permissionArray.includes(true)) {
                  item.subItems = []
                  item.path = RouterConfig.urlMap.adminProfile
                } else {
                  let subItemNavigation = item.subItems
                  if (subItemNavigation != undefined) {
                    if(this.props.features.roles) {
                      subItemNavigation = this.checkPermission(subItemNavigation, [permissions.canViewUserRole, permissions.canManageUserRole], 'navUserRoles')
                    } else {
                      subItemNavigation = this.checkPermission(subItemNavigation, [false], 'navUserRoles')
                    }
                  }
                  item.subItems = subItemNavigation
                }
              }
              return item
            })
            navigation = this.checkPermission(navigation, [permissions.canViewAccount, permissions.canManageAccount], 'navItemAccounts')
            navigation = this.checkPermission(navigation, [permissions.canViewOrganization, permissions.canManageOrganization], 'navItemOrganizations')
            navigation = this.checkPermission(navigation, [permissions.canViewAuditLog], 'navItemReporting')
          }
          return (
            <div className={styles.secureLayout} id='secureLayout_wrap'>
              <ToasterContainer
                hideNotification={function noRefCheck(){}}
                notifications={notification}
              />
              <Navigation
                licenseStatus={licenseStatus}
                history={history}
                appNavigation={navigation}
              />
              <div className={styles.contentWrap} id='content_wrap'>
                <Header {...headerProps} />
                {!withoutBreadcrumbs && <Breadcrumbs userId={userId}/>}
                <div className={styles.content} id={ID_SECURE_LAYOUT}>
                  {Component && (
                    <Component
                      title={title}
                      {...props}
                      permissions={permissions}
                    />
                  )}
                </div>
              </div>
            </div>
          )
        }}
      />
    )
  }
}

export default connect(
  (state: IAppState) => ({
    userProfile: state.userProfile.data,
    permissions: getPermissions(state),
    accessScope: state.profile.data ? state.profile.data.access_scope : null,
    isAuth: state.auth.isAuth,
    licenseStatus: state.auth.licenseStatus,
    license: state.license.data,
    accounts: state.profile.data ? state.profile.data.accounts : [],
    localeList: state.locales,
    headerPermissions: state.profile.data
      ? state.profile.data.permissions
      : undefined,
    primaryAccount: state.profile.data  ? state.profile.data.primary_account_id : '',
    userId: state.profile.data ? state.profile.data .id : '' ,
    features: state.features
  }),
  {loginSuccess, logout, fetchProfile, initLocalization, fetchLocales, fetchFeatures }
)(withLocalize(SecureLayout))